import DropdownOptions from 'app/shared/utilities/dropdown/DropdownOptions';

// dropdown data
export const delayDropdownOptions = new DropdownOptions([
  //all values are to be sent to the backend in minutes
  { value: 0, label: '0 minutes' },
  { value: 0.25, label: '15 seconds' },
  { value: 0.5, label: '30 seconds' },
  { value: 1, label: '1 minute' },
  { value: 2, label: '2 minutes' },
  { value: 3, label: '3 minutes' },
  { value: 4, label: '4 minutes' },
  { value: 5, label: '5 minutes' },
  { value: 10, label: '10 minutes' },
  { value: 15, label: '15 minutes' },
  { value: 20, label: '20 minutes' },
  { value: 30, label: '30 minutes' },
  { value: 60, label: '1 hour' },
  { value: 360, label: '6 hours' },
  { value: 720, label: '12 hours' },
  { value: 1440, label: '24 hours' },
  { value: 2880, label: '2 days' },
  { value: 4320, label: '3 days' },
  { value: 5760, label: '4 days' },
  { value: 7200, label: '5 days' },
  { value: 8640, label: '6 days' },
  { value: 10080, label: '1 week' },
]);
