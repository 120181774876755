import { useEffect, useState } from 'react';
import { useFetchFirmwareOtaLogsQuery } from 'features/ota/firmware/hooks/useFetchFirmwareOtaLogsQuery';
import DateRangeSubmitForm from 'shared/components/DateRangeSubmitForm';
import PageListWrapper, { PageListHead, PageListTitle } from 'shared/styles/components/PageList';
import { SectionHead } from 'shared/styles/components/Section';
import { formatISOTimestampToShortDate, getNow, getAMonthAgo } from 'shared/utilities/time';
import SortablePaginatedTable from 'shared/ui/table/SortablePaginatedTable';
import { SORT_ORDER } from 'shared/constants/table';
import { decorateOtaLogData } from 'features/ota/shared/utilities/logs';
import FilteredDropdown from 'app/shared/components/controls/FilteredDropdown/FilteredDropdown';
import { LogsFiltersDiv } from 'shared/styles/components/OtaUpdates';
import { ActionButtonWithIcon } from 'shared/styles/components/Button';
import { QueryAndExportDiv } from 'shared/styles/components/OtaUpdates';
import Media from 'react-media';
import { sizes } from 'shared/utilities/media';
import { Cards } from 'shared/styles/components/MobileCard';
import OtaLogCard from 'features/ota/shared/mobile/OtaLogCard';
import { createExportDownload } from 'shared/utilities/csvExport';
import { validateLogsRange } from 'shared/utilities/validators';
import { sortDescendingAlpha } from 'shared/utilities/general';
import { TruncatedTextWithWrap } from 'shared/styles/components/TruncatedText';
import { extractDropdownOptions } from 'app/shared/utilities/dropdown/dropdownUtils';

const FirmwareUpdatesLogsPage = () => {
  const defaultStartDate = formatISOTimestampToShortDate(getAMonthAgo());
  const defaultEndDate = formatISOTimestampToShortDate(getNow());

  const { fetch, isLoading, data } = useFetchFirmwareOtaLogsQuery();

  const [filteredLogs, setFilteredLogs] = useState(null);
  const [unfilteredLogs, setUnfilteredLogs] = useState(null);

  const [vehicleNameFilter, setVehicleNameFilter] = useState(null);
  const [deviceFilter, setDeviceFilter] = useState(null);
  const [actionFilter, setActionFilter] = useState(null);
  const [listingCount, setListingCount] = useState(filteredLogs?.length);

  useEffect(() => {
    decorateOtaLogData(data?.logs);
    setUnfilteredLogs(data?.logs);
    setFilteredLogs(data?.logs?.sort((v1, v2) => sortDescendingAlpha(v1.timestamp, v2.timestamp)));
  }, [data?.logs]);

  useEffect(() => {
    let filteredData = unfilteredLogs
      ?.filter(l => (vehicleNameFilter ? l.vehicle_name === vehicleNameFilter : true))
      ?.filter(l => (deviceFilter ? l.product_name === deviceFilter : true))
      ?.filter(l => (actionFilter ? l.statusDisplayName === actionFilter : true));
    setFilteredLogs(
      filteredData?.sort((v1, v2) => sortDescendingAlpha(v1.timestamp, v2.timestamp)),
    );
  }, [vehicleNameFilter, deviceFilter, actionFilter]);

  useEffect(() => {
    setListingCount(filteredLogs?.length ? filteredLogs.length : 0);
  }, [filteredLogs]);

  const prepareDataForExport = () => {
    let otaLogsForExport = unfilteredLogs.map(l => ({
      Timestamp: l.formattedTime,
      Vehicle_Name: l.vehicle_name,
      Device_Name: l.product_name,
      Version: l.version,
      Action: l.statusDisplayName,
      Authorizer: l.authorizerDisplay,
    }));

    let csvFileName = 'wcp_firmware_ota_logs_export';
    createExportDownload(otaLogsForExport, csvFileName);
  };

  const columns = [
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      searchIndex: 'formattedTime',
      key: 'timestamp',
      searchable: true,
      width: '25%',
      render(_, row) {
        return <span title={'Timestamp: ' + row.formattedTime}>{row.formattedTime}</span>;
      },
    },
    {
      title: 'Vehicle Name',
      dataIndex: 'vehicle_name',
      key: 'name',
      searchable: true,
      width: '15%',
      render(value) {
        return <span title={'Vehicle Name: ' + value}>{value}</span>;
      },
    },
    {
      title: 'Device Name',
      dataIndex: 'product_name',
      key: 'device',
      searchable: true,
      width: '20%',
      render(value) {
        return <span title={'Device Name: ' + value}>{value} </span>;
      },
    },
    {
      title: 'Device ID',
      dataIndex: 'device_id',
      key: 'device_id',
      searchable: true,
      width: '10%',
      render(value) {
        return (
          <span title={'Device ID: ' + value}>
            <TruncatedTextWithWrap>{value} </TruncatedTextWithWrap>
          </span>
        );
      },
    },
    {
      title: 'Previous Version',
      dataIndex: 'version_starting',
      key: 'version_starting',
      searchable: true,
      width: '10%',
      render(value) {
        return <span title={'Previous Version: ' + value}>{value ? value : 'N/A'}</span>;
      },
    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
      searchable: true,
      width: '10%',
      render(value) {
        return <span title={'Version: ' + value}>{value}</span>;
      },
    },
    {
      title: 'Action',
      dataIndex: 'statusDisplayName',
      key: 'action',
      searchable: true,
      width: '15%',
      render(statusDisplayName) {
        return <span title={'Action: ' + statusDisplayName}>{statusDisplayName}</span>;
      },
    },
    {
      title: 'Authorizer',
      dataIndex: 'authorizerDisplay',
      key: 'authorizer',
      searchable: true,
      width: '20%',
      render(value) {
        return <span title={'Authorizer: ' + value}>{value}</span>;
      },
    },
  ];

  return (
    <PageListWrapper>
      <PageListHead stackable logs>
        <SectionHead>
          <PageListTitle>Firmware OTA Logs</PageListTitle>
        </SectionHead>
      </PageListHead>
      <div>
        <QueryAndExportDiv>
          <DateRangeSubmitForm
            defaultStartDate={defaultStartDate}
            defaultEndDate={defaultEndDate}
            isLoading={isLoading}
            submitHandler={({ startDate, endDate }) => fetch({ startDate, endDate })}
            validationFunction={validateLogsRange}
            confirmText="Generate"
          />
          {filteredLogs?.length > 0 && (
            <ActionButtonWithIcon onClick={prepareDataForExport} logsExport>
              Export to .csv
            </ActionButtonWithIcon>
          )}
        </QueryAndExportDiv>
        <div>
          {unfilteredLogs && (
            <LogsFiltersDiv>
              <FilteredDropdown
                title={'Vehicle'}
                placeholderLabel={'All Vehicles'}
                dropdownOptions={extractDropdownOptions({
                  collection: filteredLogs,
                  propName: 'vehicle_name',
                })}
                handleItemSelect={selectedVehicle => {
                  setVehicleNameFilter(selectedVehicle ? selectedVehicle.label : null);
                }}
                defaultItem={null}
                styleOptions={{ restrictHeight: false }}
              />
              <FilteredDropdown
                title={'Device'}
                placeholderLabel={'All Devices'}
                dropdownOptions={extractDropdownOptions({
                  collection: filteredLogs,
                  propName: 'product_name',
                })}
                handleItemSelect={selectedProduct => {
                  setDeviceFilter(selectedProduct ? selectedProduct.label : null);
                }}
                defaultItem={null}
                styleOptions={{ restrictHeight: false }}
              />
              <FilteredDropdown
                title={'Action'}
                placeholderLabel={'All Actions'}
                dropdownOptions={extractDropdownOptions({
                  collection: filteredLogs,
                  propName: 'statusDisplayName',
                })}
                handleItemSelect={selectedAction => {
                  setActionFilter(selectedAction ? selectedAction.label : null);
                }}
                defaultItem={null}
                styleOptions={{ restrictHeight: false }}
              />
            </LogsFiltersDiv>
          )}
        </div>
      </div>
      {filteredLogs && (
        <>
          <h5>
            {listingCount === unfilteredLogs?.length
              ? `Total of ${listingCount} Log${listingCount !== 1 ? 's' : ''}`
              : `${listingCount} of ${unfilteredLogs?.length} Log${
                  filteredLogs?.length !== 1 ? 's' : ''
                }`}
          </h5>
          <Media
            queries={{
              tablet: { maxWidth: sizes.tablet },
              mobile: { maxWidth: sizes.mobile },
            }}
          >
            {matches =>
              matches.tablet ? (
                <div>
                  <Cards>
                    {filteredLogs.map(fld => (
                      <OtaLogCard log={fld} config={false} />
                    ))}
                  </Cards>
                </div>
              ) : (
                <SortablePaginatedTable
                  className="table-simple"
                  columns={columns}
                  tableData={filteredLogs}
                  scroll={{ x: false, y: false }}
                  rowKey={record => record.timestamp + record.device_id + record.product_id}
                  defaultSortOrder={SORT_ORDER.DESC}
                  setListingCount={setListingCount}
                  emptyText="No OTA logs are currently associated with this organization"
                />
              )
            }
          </Media>
        </>
      )}
    </PageListWrapper>
  );
};

export default FirmwareUpdatesLogsPage;
